<template>
	<b-modal id="upload-pfp-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				UPLOAD PROFILE PICTURE
			</h5> 
			<div></div>

    </template>


		<div> 
			<form>
				<div class="alert alert-danger" v-if="errors.profile.msg">
					{{ errors.profile.msg }}
				</div>          
				<label for="file">File:</label>
				<b-form-file
				v-model="file"
				:state="Boolean(file)"
				:placeholder="$t('createAsset.filePlaceholder')"
				:drop-placeholder="$t('createAsset.dropPlaceholder')"
				></b-form-file>
				<div class="flex  margin-top">
					<button  class="btn btn-main" type="submit" @click="setAccount(type, label)" :disabled="isSaving">  
						<span>{{ $t('createAsset.upload') }}</span> 
					</button>					
				</div>
			</form>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
export default {
  name: 'ExportImportFund',
  props: ["type", "label"],
  data: function() {
      return  {
      	state: null,
		isSaving: false,
		idNumber: "",
		description: "",
		file: null,
		errors: {
			profile: {
				msg: ''
			}
		}
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
   	},
   	methods: {
		setAccount: function(type, label) {
			let hasError = false

			if(this.file == null) {
				hasError = true
				this.errors.profile.msg = 'File is required'
			}

			if(!hasError) {
				let formData = new FormData();
				formData.append('avatarImageFile', this.file); 
				
				this.axios.post('/cx/uploadavatar', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
				})
				.then((response) => {
					window.location.reload();
				}).catch((err) => {
					if(err.response.data.fieldErrors) {
						this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage    
					} else {
						this.errors.profile.msg = err.response.data.msgText  
					}     
				})
			}

		},

   		close: function(){
   			this.$bvModal.hide('upload-pfp-modal'); 
   		},	
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
 	},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }
	
</style>
