<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div class="d-flex flex-wrap wrapper">
            <div class="sidebar" v-if="sideBar">
                <SideBar @close="openSidebar()" :active="'profile'"/>
            </div>
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button" @click="openSidebar">
                            <v-icon name="chevron-right" />
                        </button>
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-end align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/logout.png')" alt="" class="topbar-img my-auto" @click="logout">
                    </div>
                </div>
                <div class="profile-wrapper">
                    <h2 class="text-white">Profile</h2>
                    <!-- PERSONAL -->
                    <div class="profile-unedit text-white" v-if="!isEditing">
                        <div class="info d-flex flex-wrap mb-5">
                           <!--  <div class="mr-3">
                                <img :src="pfpString != '' ? `data:image/png;base64,${pfpString}` : require('../assets/Asset 29.png')" style="width:90px;">
                            </div> -->

                            <div class="profile-picture mr-3">
                                  <img :src="pfpString != '' ? `data:image/png;base64,${pfpString}` : require('../assets/Asset 29.png')" style="width:100%;">
                                </div>

                            <div>
                                <h4 class="name">{{ init.profile.firstName }} {{ init.profile.lastName }}</h4>
                                <h6 class="email">{{ init.profile.emailAddress }}</h6>
                                <h6 class="email">{{ getProfileType(init.profile.profileType) }}</h6>
                                <div class="info-status">{{ init.profile.verificationStatus }}</div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap justify-content-between mt-3">
                            <h5 class="text-white">Personal Data Assets</h5>
                            <div class="text-white">
                                <h4 style="cursor:pointer;" @click="toggleEdit">Edit</h4>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="title">
                                <label for="firstName">Title</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.title" readonly>
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">First Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.firstName" readonly>
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">Last Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.lastName" readonly>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Date of Birth</label>
                                <div class="form-control">{{ init.profile.birthYear }}-{{ init.profile.birthMm }}-{{ init.profile.birthDd }}</div>
                            </div>
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Gender</label>
                                <input type="text" id="firstName" class="form-control" v-model="profile.gender" readonly>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Citizenship</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.presentAddress.country" readonly>
                            </div>
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Civil Status</label>
                                <input type="text" id="firstName" class="form-control" v-model="profile.maritalStatus" readonly>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="mobileEmail" style="width:48.75%">
                                <label for="firstName">Mobile Number</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.mobilePhone" readonly>
                            </div>
                            <div class="form-group" id="mobileEmail" style="width:48.75%">
                                <label for="firstName">Email Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress" readonly>
                            </div>
                        </div>
                    </div>
                    <!-- EDITING -->
                    <div class="profile-unedit text-white" v-if="isEditing">
                        <div class="info d-flex flex-wrap mb-5">
                            <div class="mr-3" style="position:relative;padding:10px;" @click="uploadPfp">
                                <img :src="pfpString != '' ? `data:image/png;base64,${pfpString}` : require('../assets/Asset 29.png')" style="width:90px;">
                            </div>
                            <div class="my-auto">
                                <h4 class="name">{{ init.profile.firstName }} {{ init.profile.lastName }}</h4>
                                <h6 class="email">{{ init.profile.emailAddress }}</h6>
                                <div class="info-status">{{ init.profile.verificationStatus }}</div>
                            </div>
                        </div>
                        <div class="align-items-end justify-content-end text-right text-white">
                            <h4 style="cursor:pointer;" @click="toggleEdit">Cancel</h4>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="title">
                                <label for="firstName">Title</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="init.profile.title" >
                                    <option value="Mr">Mr</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Mrs">Mrs</option>
                                </select>
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">First Name</label>
                                <input type="text" id="firstName" class="form-control" disabled  v-model="init.profile.firstName">
                            </div>
                            <div class="form-group" id="name">
                                <label for="firstName">Last Name</label>
                                <input type="text" id="firstName" class="form-control" disabled v-model="init.profile.lastName">
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Date of Birth</label>
                                <div class="form-control">{{ init.profile.birthYear }}-{{ init.profile.birthMm }}-{{ init.profile.birthDd }}</div>
                            </div>
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Gender</label>
                                <select v-model="init.profile.gender" class="form-control" >
                                    <option disabled value="">Please select one</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                    <option value="O">Other</option>
                                </select>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Citizenship</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.citizenship" >
                            </div>
                            <div class="form-group" id="row2" style="width:48.75%">
                                <label for="firstName">Civil Status</label>
                                <select v-model="init.profile.maritalStatus" class="form-control" >
                                    <option disabled value="">Please select one</option>
                                    <option value="S">Single</option>
                                    <option value="M">Married</option>
                                    <option value="D">Divorced</option>
                                    <option value="A">Annulled</option>
                                    <option value="X">Separated</option>
                                    <option value="C">Cohabiting</option>
                                    <option value="W">Widowed</option>
                                </select>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" style="position:relative;width:48.75%" id="mobileEmail">
                                <label for="exampleFormControlInput1">Mobile Number</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button class="btn text-white" type="button" v-on:click="toggleCountryList">
                                            <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
                                            <span>+{{ mobileCode }}</span>
                                        </button>
                                    </div>
                                    <input type="tel" class="form-control" @keypress="isNumber($event)" v-model="init.profile.mobilePhone">
                                </div>
                                <div class="country-list" v-if="showCountry">
                                    <div class="row">
                                        <div class="col-sm-6" v-for="l in list.mobileCodes" v-on:click="selectCountry(l)">
                                            <div>
                                            <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                                            </div>
                                            <div class="country-name"> <span> {{ l.country }} </span> </div>     
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" id="mobileEmail" style="width:48.75%">
                                <label for="firstName">Email Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress" disabled>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div></div>
                            <button class="btn btn-main mt-3" style="width:20%" @click="saveProfile">SAVE</button>
                        </div>
                    </div>

                    <!-- BUSINESS -->
                    <div class="profile-unedit text-white" v-if="!isEditingBusiness">
                        <div class="d-flex flex-wrap justify-content-between">
                            <h5 class="text-white">Employment Data Assets</h5>
                            <div class="text-white">
                                <h4 style="cursor:pointer;" @click="toggleEditBusiness">Edit</h4>
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="mobileEmail" style="width:31.66%">
                                <label for="firstName">Business Website</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.mobilePhone" readonly>
                            </div>
                            <div class="form-group" id="mobileEmail" style="width:31.66%">
                                <label for="firstName">Business Office Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress" readonly>
                            </div>
                            <div class="form-group" id="mobileEmail" style="width:31.66%">
                                <label for="firstName">Business Email Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress" readonly>
                            </div>
                        </div>
                    </div>
                    <!-- EDITING -->
                    <div class="profile-unedit text-white" v-if="isEditingBusiness">
                        <div class="align-items-end justify-content-end text-right text-white">
                            <h4 style="cursor:pointer;" @click="toggleEditBusiness">Cancel</h4>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between">
                            <div class="form-group" id="mobileEmail" style="width:31.66%">
                                <label for="firstName">Business Website</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.mobilePhone">
                            </div>
                            <div class="form-group" id="mobileEmail" style="width:31.66%">
                                <label for="firstName">Business Office Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress">
                            </div>
                            <div class="form-group" id="mobileEmail" style="width:31.66%">
                                <label for="firstName">Business Email Address</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress">
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div></div>
                            <button class="btn btn-main mt-3" style="width:20%" @click="saveProfile">SAVE</button>
                        </div>
                    </div>

                    <!-- IDS -->
                    <div class="profile-unedit">
                        <h5 class="text-white">IDs</h5>
                        <div action="" class="text-white gov-id" v-for="(poid, index) in dataAssets" :key="poid.id">
                            <div class="input-group" v-if="poid.type != 'SELFIE' && poid.type != 'OTHER'">
                                <!-- ID Type -->
                                <div class="input-group-prepend">
                                    <label v-if="index == 0" style="display:block;">ID Type</label>
                                    <input type="text" class="form-control text-white" v-model="poid.type" disabled>
                                </div>

                                <!-- Description -->
                                <div class="form-group">
                                    <label v-if="index == 0" style="display:block;">Label</label>
                                    <input type="text" class="form-control text-white" v-model="poid.label" placeholder="No asset uploaded" disabled>
                                </div>

                                <!-- View and Upload Buttons -->
                                <div class="input-group-append align-items-center">
                                    <label style="visibility:hidden;" v-if="index == 0">Actions</label>
                                    <div class="d-flex flex-nowrap">
                                        <button class="btn btn-main mx-1" :disabled="!poid.hasAsset" @click="viewPoid(poid.key, poid.label)">VIEW</button>
                                        <button class="btn btn-main mx-1" @click="uploadPoid(poid.type, poid.label)">{{ poid.hasAsset ? 'UPDATE' : 'UPLOAD'}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <p style="color:gray; font-size: 10px; margin-left:10px;">version 1.001</p>
            </div>
            
        </div>
        <ChangePin @close="exitModal"/>
        <ConfirmLogout />
        <UploadPfp />
        <UploadProfilePoid :idType="poidUpload.idType" :idLabel="poidUpload.idLabel" @uploaded="update()"/> 
        <ViewAsset :asset="assetString" :label="assetLabel" />
    </div>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import UploadPfp from '@/components/modal/UploadPfp.vue'
import DatePicker from 'vue2-datepicker';
import UploadProfilePoid from '@/components/modal/UploadProfilePoid.vue'
import ViewAsset from '@/components/modal/ViewAsset.vue'
import SideBar from '@/components/SideBar.vue'
import 'vue2-datepicker/index.css';
export default {
    components: {
        ChangePin,
        ConfirmLogout,
        DatePicker,
        UploadPfp,
        UploadProfilePoid,
        SideBar,
        ViewAsset
    },
    data() {
        return {
            sideBar: false,
            modalState: 'main',
            hasMobile: false,
            isSaved: false,
            country: 'PH',
            mobileCode: '63',
            showCountry: false,
            list: {
            mobileCodes: []
            },
            isSaving: {
                profile: false,
                contact: false,
                account: false
            },
            isEditing: false,
            isEditingBusiness: false,
            config: {
                file: null,
                id: ''
            },
            errors: {
                file: '',
                profile: ''
            },
            poids: {},
            dataAssets: [
                {type: 'POA', label: 'Proof of Address', hasAsset: false, key: ''},
                {type: 'HOMEASSET', label: 'Home Asset', hasAsset: false, key: ''},
                {type: 'VEHICLEASSET', label: 'Vehicle Asset', hasAsset: false, key: ''},
                {type: 'EDUCATION', label: 'Educational Certificate', hasAsset: false, key: ''},
                {type: 'EMPLOYMENT', label: 'Employment Certificate', hasAsset: false, key: ''},
                {type: 'NBICLEARANCE', label: 'NBI Clearance', hasAsset: false, key: ''},
                {type: 'VOTERSID', label: "Voter's ID", hasAsset: false, key: ''},
                {type: 'PASSPORTNUMBER', label: "Passport", hasAsset: false, key: ''},
            ],
            profile: {
                birthDate: '',
                gender: '',
                maritalStatus: ''
            },
            pfpString: "",
            assetString: "",
            assetLabel: "",
            poidUpload: {
                idType: '',
                idLabel: '',
            },
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        getProfileType(type) {
            if(type == 'I') return "Individual";
            if(type == 'B') return "Business";
            if(type == 'G') return "Government";
        },
        update() {
            window.location.reload();
        },
        openSidebar() {
            this.sideBar = !this.sideBar
        },
        privateKey() {
            this.modalState = 'privateKey'
        },
        changePin() {
            this.$bvModal.show('change-pin-modal'); 
            this.modalState = 'changePin'
        },

        exitModal() {
            this.modalState = 'main'
        },
        uploadPfp() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('upload-pfp-modal');
        },
        importPrivateKey() {
            this.$bvModal.show('import-key-modal'); 
        },
        changeState(state) {
            this.state = state
        },
        dashboard() {
            this.$router.push('/')
        },
        transactions() {
            this.$router.push('/transactions')
        },
        toggleEdit() {
            this.isEditing = !this.isEditing
        },
        toggleEditBusiness() {
            this.isEditingBusiness = !this.isEditingBusiness
        },
        toggleCountryList: function(){
            this.showCountry = !this.showCountry;
        },

        uploadPoid(idType, idLabel) {
            this.poidUpload.idType = idType
            this.poidUpload.idLabel = idLabel
            console.log('.213122')
            this.$bvModal.show('upload-profile-poid-modal'); 
        },

        getMobileCodes: function() {
            this.axios.get('/assets/mobilecodes.json')
                .then((response) => {  
                    this.list.mobileCodes = response.data; 
                }).catch((err) => {
                    console.log(err);
                })
        },

        selectCountry: function(c) {
            this.showCountry = false;
            this.country = c.countryCode;
            this.mobileCode = c.mobileCode; 
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        getPoids() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids',
            };
            this.axios(options)
                .then((response) => {
                    const uniquePoids = response.data.filter((item, index, self) =>
                        index === self.findIndex((t) => t.poidType === item.poidType)
                    );

                    uniquePoids.forEach((value) => {
                        this.dataAssets.forEach((asset) => {
                            if(value.poidType == asset.type) {
                                asset.label = value.poidDesc
                                asset.key = value.poidKey

                                if(value.poidType == 'PASSPORTNUMBER') {
                                    asset.type = 'PASSPORT'
                                }
                                if(asset.label == 'Passport Number') {
                                    asset.label = 'Passport'
                                }
                                asset.hasAsset = true
                            }
                        })
                    })

                    console.log(this.dataAssets)

                    this.poids = uniquePoids;
                    console.log('poids (no duplicates by poidType):', uniquePoids);

                    response.data.forEach((value) => {
                       
                      if(value.poidType == "SELFIE" 
                        ) {

                          this.getSelfie(value.poidKey)

                      } 
                    })
                
                })
                .catch((error) => {
                    
                });
        },

        getSelfie(key) {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids/image/' + key,
            };
            this.axios(options)
                .then((response) => { 

                    this.pfpString = response.data
                })
                .catch((error) => {
                    
                });
        },

        viewPoid(key, label) {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids/image/' + key,
            };
            this.axios(options)
                .then((response) => { 

                    this.assetString = response.data
                    this.assetLabel = label
                    this.$bvModal.show('view-modal'); 
                })
                .catch((error) => {
                    
                });
        },
        
        saveProfile: function() {
            this.isSaving = true
            let birthYear = this.profile.birthDate.substring(0, this.profile.birthDate.indexOf('-'))
            let birthMm = this.profile.birthDate.substring(this.profile.birthDate.indexOf('-') + 1, this.profile.birthDate.indexOf('-') + 3)
            let birthDd = this.profile.birthDate.substring(this.profile.birthDate.indexOf('-') + 4, this.profile.birthDate.length)
            const params = {
                birthDd: this.init.profile.birthDd,
                birthMm: this.init.profile.birthMm,
                birthYear: this.init.profile.birthYear,
                firstName: this.init.profile.firstName,
                homePhone: '-',
                lastName: this.init.profile.lastName,
                employer: this.init.profile.employer,
                homePhone: '-',
                identityNumber: '-',
                middleName: '-',
                mothersMaidenName: '-',
                nationality: this.init.profile.presentAddress.country,
                natureOfWork: this.init.profile.natureOfWork,
                occupation: this.init.profile.occupation,
                'permanentAddress.addressExtraInfo': '-',
                'permanentAddress.cityTown': this.init.profile.presentAddress.cityTown,
                'permanentAddress.country': this.init.profile.presentAddress.country,
                'permanentAddress.numberStreet': this.init.profile.presentAddress.numberStreet,
                'permanentAddress.postalCode': this.init.profile.presentAddress.postalCode,
                'permanentAddress.provState': this.init.profile.presentAddress.provState,
                placeOfBirth: '-',
                'presentAddress.addressExtraInfo': '',
                'presentAddress.cityTown': this.init.profile.presentAddress.cityTown,
                'presentAddress.country': this.init.profile.presentAddress.country,
                'presentAddress.numberStreet': this.init.profile.presentAddress.numberStreet,
                'presentAddress.postalCode': this.init.profile.presentAddress.postalCode,
                'presentAddress.provState': this.init.profile.presentAddress.provState,
                sourceOfFunds: '-',
                title: this.init.profile.title,
                citizenship: this.init.profile.citizenship,
                maritalStatus: this.init.profile.maritalStatus,
                gender: this.init.profile.gender,

            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/profile/updatebasic',
            };

            this.axios(options)
                .then(() => { 
                    this.isSaved = true
                    this.getProfile();
                    this.isEditing = false
                }).catch((err) => {
                    this.isSaving = false
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        updateEmail: function() {
    
            const params = {
                emailAddress: this.init.profile.emailAddress,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updateemail',
            };

            this.axios(options)
                .then(() => { 
                    this.getProfile();
                }).catch((err) => {
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        updateMobile: function() {
    
            const params = {
                mobilePhone: this.init.profile.mobilePhone,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updatemobile',
            };

            this.axios(options)
                .then(() => { 
                    this.getProfile();
                }).catch((err) => {
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        onUpload: function(){
            let hasError = false;
                if(this.config.file == '' || this.config.file == undefined || this.config.file == null) {
                    this.errors.file = 'File is required.';
                    hasError = true;
                }  

                if(this.config.id == '' || this.config.id == undefined || this.config.id == null) {
                    this.errors.file = 'ID is required.';
                    hasError = true;
                }  

                if(!hasError) {
                    this.showError = false;
                    let formData = new FormData();
                    formData.append('poidType', "ACRICR"); 
                    formData.append('poidDescription', "ACRICR");
                    formData.append('poidNumber', this.config.id);
                    formData.append('poidImageFile', this.config.file);

                    this.axios.post('/cx/uploadpoid',
                                    formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => { 

                    this.showError = true;
                        this.systemMsg = "Config uploaded successfully";
                        this.config.file = null;  
                        this.$emit("close")
                        this.$emit("done")
                        
                    }).catch((err) => {
            
            
                        if(err) {
                        this.showError = true;
                        this.systemMsg = err.response.data.msgText;
                        setTimeout(() => {
                            this.errors.file = ""
                        }, 5000)
                        } 

                    })
                }

        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/setup-profile";
                },1000)
                    
                }  

                })
        },
        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
                .then((response) => {
                this.getPoids();
                this.init.profile = response.data;
                this.init.entityId = this.init.profile.entityId;
                if(this.init.profile.mobileNumber == null) {
                    this.hasMobile = false
                } else {
                    this.hasMobile = true
                }

                if(this.init.profile.gender == 'M') {
                    this.profile.gender = 'Male'
                } else if(this.init.profile.gender == 'F') {
                    this.profile.gender = 'Female'
                } else if(this.init.profile.gender == 'O') {
                    this.profile.gender = 'Other'
                }

                if(this.init.profile.maritalStatus == 'S') {
                    this.profile.maritalStatus = 'Single'
                } else if(this.init.profile.maritalStatus == 'M') {
                    this.profile.maritalStatus = 'Married'
                } else if(this.init.profile.maritalStatus == 'D') {
                    this.profile.maritalStatus = 'Divorced'
                } else if(this.init.profile.maritalStatus == 'X') {
                    this.profile.maritalStatus = 'Separated'
                } else if(this.init.profile.maritalStatus == 'W') {
                    this.profile.maritalStatus = 'Widowed'
                } else if(this.init.profile.maritalStatus == 'C') {
                    this.profile.maritalStatus = 'Cohabiting'
                } else if(this.init.profile.maritalStatus == 'A') {
                    this.profile.maritalStatus = 'Annulled'
                } 


                    
                })
        },
    },
    mounted() {
        this.checkSession();
        this.getMobileCodes();

        // this.staticDataAssets = [
        //   { index: 0, name: "Email Address" },
        //   { index: 1, name: "Birthdate" },
        //   { index: 2, name: "Mobile Number" },
        //   { index: 3, name: "Gender" },
        //   { index: 4, name: "Citizenship" },
        //   { index: 5, name: "Govt Valid ID" },
        //   { index: 6, name: "Home Asset" },
        //   { index: 7, name: "Educational Certificate" },
        //   { index: 8, name: "Employment Certificate" },
        //   { index: 9, name: "Vehicle Asset" },
        //   { index: 10, name: "NBI Clearance" },
        //   { index: 11, name: "Voter's ID" },
        //   { index: 12, name: "Civil Status" },
        //   { index: 13, name: "Full name" },
        //   { index: 14, name: "Face" }
        // ];

        

        
    }
}
</script>

<style scoped lang="scss">

.profile-picture {
  width: 100px; 
  height: 100px;
  border-radius: 3px; 
  overflow: hidden; 
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; 
}

    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                margin:20px auto;
                width: 15em;
                cursor:pointer;
                &.active {
                    border-right:2px #0e649e solid;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 100px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .profile-wrapper {
                padding:50px 70px;
                .profile-unedit {
                    width:100%;
                    padding:30px;
                    border-radius:10px;
                    #title {
                        width:15%;
                    }
                    #name {
                        width:40%;
                    }
                    #row2 {
                        width:31.66%;
                    }
                    #row3 {
                        width:31.66%;
                    }
                    #mobileEmail {
                        width:47.5%;
                    }
                    #row4 {
                        width:31.66%;
                    }
                    .name {
                        margin:0;
                    }
                    .info-status {
                      background:#0567d7;
                      text-align:center;
                      padding:5px;
                      border-radius:5px;
                      width:10em;  
                    }
                    .default-flag {
                        width: 33px;
                        margin-right:0.25em;
                    }

                    .country-list {
                        position: absolute;
                        background: #ccc;
                        color: #333;
                        top: 70px;
                        padding: 0.5em;
                        height: 13em;
                        overflow: auto;
                        z-index: 3;
                        .row {
                            margin: 0 !important;
                            .col-sm-6 {
                                display: flex;
                                padding: 0.5em;
                                align-items: center;
                                cursor: pointer;
                                &:hover {
                                    background: darken(#ccc, 10%);
                                }
                                .country-name {
                                    margin-left: 0.5em;
                                }

                                span {
                                    font-size: 0.9em;
                                }
                            }
                        }
                    }

                    .flag-list-img {
                    width: 33px;
                    }

                    .mx-datepicker  {
                        width:100%;
                    }
                    .gov-id {
                        overflow:auto;
                        .input-group-prepend {
                            display:block;
                            width:35%;
                            input {
                                background:none;
                            }
                        }
                        .form-group {
                            width:40%;
                        }
                        input {
                            border:1px #0567d7 solid;
                            background:none;
                        }
                        
                        .input-group-append {
                            width:25%;
                            display:block;
                            height:calc(1.5em + 0.75rem + 2px);
                            .input-group-text {
                                width:100%;
                                background:none;
                                border:1px #0567d7 solid;
                                color:white;
                                font-family:SSN-Light;
                                text-align:center;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:800px) {
        .wrapper {
            .main-body {
                .profile-wrapper {
                    padding:20px 20px;
                    .profile-unedit {
                        #title {
                            width:100%;
                        }

                        #name {
                            width:100%;
                        }

                        #row2 {
                            width:100%;
                        }

                        #row3 {
                            width:100%;
                        }

                        #mobileEmail {
                            width:100%;
                        }

                        #row4 {
                            width:100%;
                        }

                        .gov-id {
                            .form-group {
                                width:50%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:650px) {
        .wrapper {
            .main-body {
                padding:10px;
                .profile-wrapper {
                    padding:0px;
                    .profile-unedit {
                        padding:10px;
                        .gov-id {
                            width:100%;
                        }
                    }
                }
            }
        }
    }
</style>