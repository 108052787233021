<template>
	<b-modal id="upload-profile-poid-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
                UPLOAD <span style="text-transform:uppercase;">{{ idLabel }}</span>
            </h5>
			<div></div>

    </template>


		<div> 
			<form v-if="state == 'init'">
				<div class="alert alert-success" v-if="errors.msg">
					{{ errors.msg }}
				</div>
                <div class="alert alert-success" v-if="success.msg">
					{{ success.msg }}
				</div>
				<div>
                    <b-form-file
                    v-model="file"
                    :state="Boolean(file)"
                    :placeholder="$t('createAsset.filePlaceholder')"
                    :drop-placeholder="$t('createAsset.dropPlaceholder')"
                    ></b-form-file>

                    <!-- <div class="form-group mt-2">
                        <label for="">ID Number</label>
                        <input type="text" class="form-control" v-model="idNumber">
                    </div> -->
                    <button class="btn btn-main mt-1" style="margin:0;" @click.prevent="uploadId()">UPLOAD</button>
                </div>
			</form>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
 import Pusher from 'pusher-js' 

export default {
  name: 'ExportImportFund',
  props: ["idType", "idLabel"],
  data: function() {
      return  {
      	state: 'init',
		isSaving: false,
		idNumber: "1",
		description: "",
		file: null,
		profile: {},
		errors: {
			msg: ''
		},
        success: {
            msg: ''
        },
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
   	},
   	methods: {
 
   		done() {
   			window.location.reload();
   		},

        uploadId: function() {
			let hasError = false
			// if(this.idNumber == '') {
			// 	hasError = true
			// 	this.errors.msg = 'ID Number is required'
			// }

			if(this.file == null) {
				hasError = true
				this.errors.msg = 'File is required'
			}

			if(!hasError) {
				let formData = new FormData();
				formData.append('poidType', this.idType); 
				formData.append('poidDescription', this.idLabel); 
				formData.append('poidNumber', this.idLabel); 
				formData.append('poidImageFile', this.file); 
				
				this.isSaving = true;
				this.axios.post('/cx/uploadpoid', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
				})
				.then((response) => {
					this.$emit('uploaded')
				}).catch((err) => {
					this.isSaving = false;
					if(err.response.data.fieldErrors) {
					this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage    
					} else {
					this.errors.profile.msg = err.response.data.msgText  
					setTimeout(() => {
						this.errors.profile.msg = ""
                  	}, 3000)
					}
				})
			}

		},

   		close: function(){
   			this.$bvModal.hide('upload-profile-poid-modal'); 
   		},	
   	},
   	mounted(){

 	},

 	watch: {
      'idType': function(newValue, oldValue) {

        if (newValue != oldValue) {
        	this.description = newValue;
        }
      },

    }
}
</script>
 
<style scoped lang="scss">
	 
	 .bold {
	 	font-weight: bold;
	 }
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }
	
</style>